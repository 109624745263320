import SvgColor from '../../../components/svg-color';

// Function to generate icons
const icon = (name) => (
  <SvgColor src={`${process.env.PUBLIC_URL}/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, color: '#fff' }} />
);

const storedPermissions = JSON.parse(localStorage.getItem('user')) || [];
const permissionForAdministrator = storedPermissions.administrator;

/**
 * Implement permissions for nav
 *
 * Author: Muhammad Rooman 
 * Date: 08 August, 2024
 * 
 * Update: Conditionally add items based on permissionForAdministrator
 * Date: 29 August, 2024
 * 
 */
const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
    name: 'View-Dashboard',
  },
  {
    title: 'Account',
    path: '/profile',
    icon: icon('ic_user'),
    name: 'Account',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    icon: icon('contact'),
    name: 'Contact Us',
  },
];

// Conditionally add items based on permissionForAdministrator
if (permissionForAdministrator) {
  navConfig.push(
    {
      title: 'Teachers',
      path: '/teachers',
      icon: icon('users-management'),
      name: 'Teacher',
    },
    {
      title: 'Subscriptions',
      path: '/subscriptions',
      icon: icon('plans'),
      name: 'Subscriptions',
    },
    {
      title: 'Billing',
      path: '/billing',
      icon: icon('billings'),
      name: 'Billing',
    }
  );
} else {
  navConfig.push(
    {
      title: 'Upload Task',
      path: '/upload-task',
      icon: icon('upload'),
      name: 'UploadTask',
    },
    {
      title: 'Credentials',
      path: '/credentials',
      icon: icon('ic_user'),
      name: 'Credentials',
    }
  );
}

export default navConfig;
