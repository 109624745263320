import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import { ENV } from '../../../config/config';

/**
 * Fetch Teacher Profile data by ID.
 *
 * Author: Muhammad Rooman
 * Date: 1 August, 2024
 */
export const fetchTeacherProfileData = createAsyncThunk(
  'userProfile/fetchData',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ENV.appBaseUrl}/teacher/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.teacher;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/**
 * Update the profile image of the Teacher.
 *
 * Author: Muhammad Rooman
 * Date: 1 August, 2024
 */
export const editTeacherProfileImage = createAsyncThunk(
  'userProfile/editImage',
  async (formData, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    try {
      const response = await axios.put(`${ENV.appBaseUrl}/teacher/edit-profile-image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.teacher;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dataSlice = createSlice({
  name: "userProfile",
    initialState: {
    teacher: null,
    userImage: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // Fetch teacher data by ID
      .addCase(fetchTeacherProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeacherProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.teacher = action.payload;
        state.userImage = action?.payload?.profileImage;
      })
      .addCase(fetchTeacherProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.error.message;
      })
      // Update profile image
      .addCase(editTeacherProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editTeacherProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.userImage = action?.payload?.profileImage;
        localStorage.setItem('profileImage', action?.payload?.profileImage);
        toast.success("Profile image updated successfully");
      })
      .addCase(editTeacherProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.error.message;
        toast.error(action.payload?.message || action.error.message);
      });
  },
});

export default dataSlice.reducer;
