import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { ENV } from '../../../config/config';
import api from '../../../config/axios-instance';

export default function AccountPopover() {
    const userProfiledata = useSelector((state) => state.UserProfileData);
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem('token'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [open, setOpen] = useState(null);
    const [tearcherProfileData, setTearcherProfileData] = useState();

   console.log("userProfiledata00",userProfiledata);

    /**
     * Fetches Teacher Profile Data from the sever
     *
     * Author: Muhammad Rooman
     * Date: September 3, 2024
     */
    async function fetchData() {
        try {
            const response = await api.get(`${ENV.appBaseUrl}/teacher/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response?.data?.success) {
                setTearcherProfileData(response?.data?.teacher);
             } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    }

    useEffect(() => {
       fetchData();
    }, []);

    const MENU_OPTIONS = [
        {
            label: 'Profile', 
        },
    ];

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

     /**
     * Close the popover menu and navigate based on the label
     *
     * Author: Muhammad Rooman
     * Date: September 3, 2024
     */
    //  const handleClose = (label) => {
    //     setOpen(null);
    //     let destination = '/profile'; // Default to the profile path
    //     if (label === 'logout') {
    //         destination = '/logout'; 
    //     }
    //     setTimeout(() => {
    //         navigate(destination);
    //     }, 500);
    // };
    const handleClose = (label) => {
        setOpen(null);
        if (label === 'Profile') {
            setTimeout(() => {
                navigate('/profile');
        }, 500);
        }
    };
    
    const handleLogout = async () => {
        try {
            const response = await api.post(`${ENV.appBaseUrl}/auth/logout/${userId}`);
            if (response.data.success) {
                setOpen(null);
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('userId');
                navigate('/login');
            } else {
                toast.error(response.data?.message);
            }
        } catch (error) {
            toast.error(error?.message);
        }
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar
                    src={userProfiledata?.teacher?.profileImage 
                        ? `${ENV.file_Url}/${userProfiledata?.teacher?.profileImage}`
                        : `${ENV.file_Url}/${tearcherProfileData?.profileImage}`}
                />
                
              
            </IconButton>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {tearcherProfileData?.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {tearcherProfileData?.email}
                    </Typography>
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClose(option.label)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
