import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import PrivateRoute from './privateRoute';
// Lazy loading is used to optimize performance.
const DashboardPermissionPage = lazy(() => import('././pages/dashboardPermission/DashboardPermission'));
const TeacherPage = lazy(() => import('./pages/teacher/Teacher'));
const TaskDetailsPage = lazy(() => import('./pages/taskDetails/TaskDetails'));
const EditTeacherPage = lazy(() => import('./pages/teacher/EditTeacher'));
const CreateTeacherPage = lazy(() => import('./pages/teacher/CreateTeacher'));
const SubscriptionsPage = lazy(() => import('./pages/subscriptions/Subscriptions'));
const BillingPage = lazy(() => import('./pages/billing/Billing'));
const SupportPage = lazy(() => import('./pages/support/support'));
const CredentialsPage = lazy(() => import('./pages/credentials/Credentials'));
const UploadTaskPage = lazy(() => import('./pages/uploadTask/UploadTask'));
const ConatctUsPage = lazy(() => import('./pages/contactUs/CreateContactUs'));
const Profile = lazy(() => import('./pages/Profile'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const Page404 = lazy(() => import('./pages/Page404'));

export default function Router() {
    /**
     * Create routes and Implement permissions on routes
     *
     * Author: Muhammad Rooman
     * Date: 20 August, 2024
     */

    const isAuthenticated = !!localStorage.getItem('token');

    const routes = useRoutes([
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard" />, index: true },
                {
                    path: 'dashboard',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                <DashboardPermissionPage />
                            }
                        />
                    ),
                },
                {
                    path: 'teachers',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                <TeacherPage />
                            }
                        />
                    ),
                },
                {
                    path: 'teachers/create',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={<CreateTeacherPage />}
                        />
                    ),
                },
                {
                    path: 'teachers/edit/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={ <EditTeacherPage />}
                        />
                    ),
                },
                {
                    path: 'subscriptions',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                <SubscriptionsPage />
                            }
                        />
                    ),
                },
                {
                    path: 'support',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                <SupportPage />
                            }
                        />
                    ),
                },
                {
                    path: 'billing',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                <BillingPage />
                            }
                        />
                    ),
                },
                {
                    path: 'upload-task',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={
                                <UploadTaskPage />
                            }
                        />
                    ),
                },
                {
                    path: 'task-details/:id',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={ <TaskDetailsPage />}
                        />
                    ),
                },
                {
                    path: 'profile',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={<Profile />}
                        />
                    ),
                },   
                {
                    path: 'contact-us',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={<ConatctUsPage />}
                        />
                    ),
                },   
                {
                    path: 'credentials',
                    element: (
                        <PrivateRoute
                            isAuthenticated={isAuthenticated}
                            element={<CredentialsPage />}
                        />
                    ),
                },   
            ],
        },
        {
            path: '/login',
            element: <LoginPage />,
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="/login" />, index: true },
                {
                    path: '404',
                    element: <Page404 />,
                },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
